import {Fragment, useState} from "react";
import Link from "next/link";
import {Transition} from "@headlessui/react";

import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";
import {MenuItem} from "@/graphql/sdk/__generated__";

interface SubNavGroupProps {
  className?: string;
  clickToOpen?: boolean;
  itemClassName?: string;
  itemTitle: string;
  itemUrl?: string;
  linkClassName?: string;
  subNav: MenuItem[];
  subNavClassName?: string;
}

export const SubNavGroup = ({
                              className,
                              clickToOpen,
                              itemTitle,
                              itemClassName,
                              itemUrl,
                              linkClassName,
                              subNav,
                              subNavClassName,
                            }: SubNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn("has-sub-nav", className, isOpen ? "is-active" : "")}
      onMouseEnter={() => !clickToOpen && setIsOpen(true)}
      onMouseLeave={() => !clickToOpen && setIsOpen(false)}
    >
      {clickToOpen ? (
          <>
            {itemUrl && isOpen ? (
              <MainItemLink url={itemUrl} title={itemTitle} className={itemClassName}/>
            ) : (
              <button
                className={cn("inline-flex items-center gap-2", itemClassName)}
                onClick={() => setIsOpen(!isOpen)}
                type="button"
              >
                <span>{itemTitle}</span>
                <Icon
                  name="angle-down"
                  className={cn("h-4 w-4", isOpen ? "scale-y-[-1]" : "")}
                />
              </button>
            )}
          </>
        )
        : (
          <MainItemLink url={itemUrl} title={itemTitle} className={itemClassName}/>
        )
      }

      <Transition
        as={Fragment}
        show={isOpen}
        unmount={false}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <ul className={cn("sub-nav", subNavClassName)}>
          {subNav.map((item) => (
            <li
              key={item.id}
              className="flex items-center justify-between gap-4"
            >
              <Link href={item.url} className={linkClassName}>
                {item.title}
              </Link>
              {item.extras && item.extras.suffix && (
                <span className="rounded-xl bg-yellow px-1.5 py-0.5 text-xs text-black">
                  {item.extras.suffix}
                </span>
              )}
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
};

const MainItemLink = ({className, url, title}) => {
  return (
    <Link
      className={cn("inline-flex items-center gap-2", className)}
      href={url}
    >
      <span>{title}</span>
      <Icon
        name="angle-down"
        className={cn("h-4 w-4")}
      />
    </Link>
  )
}
