import {create} from "zustand";

import {AvailableTargetAudience} from "@/types/generic";

interface UseTargetAudience {
  setTargetAudience: (targetAudience: AvailableTargetAudience) => void;
  targetAudience: AvailableTargetAudience;
}

export const useTargetAudience = create<UseTargetAudience>((set) => ({
  targetAudience: AvailableTargetAudience.EMPLOYEE,
  setTargetAudience: (targetAudience) => {
    set(() => ({ targetAudience: targetAudience }));
  },
}));
