import * as process from "process";

import { cn } from "@/helpers/className";
import { generateHomepageUrl } from "@/helpers/url";
import { useTargetAudience } from "@/store/targetAudience";
import { AllowedLanguages, AvailableTargetAudience } from "@/types/generic";

interface ToggleAudienceProps {
  className?: string;
  i18n: {
    employee: string;
    employer: string;
  };
  locale: AllowedLanguages;
}

export const ToggleAudience = ({
  className,
  i18n,
  locale,
}: ToggleAudienceProps) => {
  const { targetAudience, setTargetAudience } = useTargetAudience();
  const buttons = [
    {
      title: i18n.employee,
      value: AvailableTargetAudience.EMPLOYEE,
      url: new URL(`${process.env.NEXT_PUBLIC_BASE_URL}/${locale}`),
    },
    {
      title: i18n.employer,
      value: AvailableTargetAudience.EMPLOYER,
      url: new URL(
        `${process.env.NEXT_PUBLIC_BASE_URL}${generateHomepageUrl(AvailableTargetAudience.EMPLOYER, locale)}`,
      ),
    },
  ];

  return (
    <div
      className={cn(
        "flex items-center rounded-[48px] border bg-white p-0.5",
        className,
      )}
    >
      {buttons.map((button, index) => (
        <button
          type="button"
          onClick={() => {
            if (button.url) window.location.href = button.url;

            setTargetAudience(button.value);
          }}
          className={cn(
            "btn rounded-btn h-[25px] p-2 text-xs font-normal leading-none lg:text-sm xl:h-[34px] xl:p-3",
            targetAudience === button.value
              ? "bg-brown text-white"
              : "bg-transparent text-black-300",
          )}
          key={index}
        >
          {button.title}
        </button>
      ))}
    </div>
  );
};
