import { SocialLink } from "@/components/atoms/SocialLink";
import { cn } from "@/helpers/className";

interface FooterSocialLinksProps {
  className?: string;
}

export const FooterSocialLinks = ({ className }: FooterSocialLinksProps) => {
  return (
    <div className={cn("flex gap-2", className)}>
      <SocialLink
        type="linkedin"
        url="https://www.linkedin.com/company/vivaldis/"
      />
      <SocialLink
        type="facebook"
        url="https://www.facebook.com/vivaldisinterim"
      />
      <SocialLink
        type="twitter"
        url="https://www.twitter.com/vivaldisinterim"
      />
    </div>
  );
};
