export const Logo = () => {
  return (
    <>
      <span className="sr-only">Vivaldis Interim</span>
      <svg
        className="logo w-[125px] 2xl:w-[167px]"
        viewBox="0 0 167 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <path
              d="M148.346 30.8183C149.122 30.8183 149.873 30.1788 150.01 29.3934C150.146 28.6079 149.623 27.9746 148.84 27.9746C148.058 27.9746 147.313 28.611 147.173 29.3934C147.037 30.1788 147.557 30.8183 148.346 30.8183Z"
              fill="#564018"
            />
            <path
              d="M60.9558 30.8183C61.7383 30.8183 62.4836 30.1788 62.6228 29.3934C62.7651 28.6079 62.2362 27.9746 61.4568 27.9746C60.6774 27.9746 59.9259 28.611 59.7898 29.3934C59.6506 30.1788 60.1702 30.8183 60.9589 30.8183H60.9558Z"
              fill="#564018"
            />
            <path
              d="M83.1558 34.373C82.3857 34.2333 81.6558 34.7517 81.5197 35.5247L79.7321 45.7137C78.4888 52.7702 68.9445 54.3038 64.9919 54.6329L68.261 36.0183C68.397 35.2422 67.8805 34.5096 67.1135 34.373C66.3434 34.2333 65.6073 34.7517 65.4713 35.5247L61.8991 55.9026C61.8249 56.3124 61.9362 56.7346 62.2084 57.0575C62.4774 57.3835 62.8764 57.5698 63.2908 57.5698C63.996 57.5698 80.5517 57.4363 82.5218 46.2073L84.3094 36.0183C84.4486 35.2422 83.9321 34.5096 83.1589 34.373H83.1558Z"
              fill="#564018"
            />
            <path
              d="M54.1393 34.373C53.3661 34.2333 52.6424 34.7517 52.5001 35.5247L50.7125 45.7137C49.4722 52.7702 39.9248 54.3038 35.9691 54.6329L39.2382 36.0183C39.3774 35.2422 38.864 34.5096 38.0908 34.373C37.3207 34.2333 36.5877 34.7517 36.4547 35.5247L32.8732 55.9026C32.8021 56.3124 32.9135 56.7346 33.1825 57.0575C33.4516 57.3835 33.8444 57.5698 34.265 57.5698C34.9702 57.5698 51.5259 57.4363 53.4929 46.2073L55.2867 36.0183C55.4228 35.2422 54.9125 34.5096 54.1362 34.373H54.1393Z"
              fill="#564018"
            />
            <path
              d="M98.7497 34.3481C92.493 34.3481 86.5177 39.4457 85.4136 45.7106C84.8569 48.8803 85.5898 51.8699 87.4795 54.13C89.3321 56.3435 92.06 57.5667 95.1682 57.5667C98.5301 57.5667 101.812 56.0796 104.298 53.7388L103.918 55.8995C103.788 56.6726 104.298 57.4021 105.071 57.5449C105.149 57.5573 105.235 57.5667 105.316 57.5667C105.993 57.5667 106.59 57.0793 106.707 56.3901L108.495 46.2011C109.049 43.0283 108.319 40.0418 106.429 37.7848C104.576 35.5651 101.846 34.3481 98.7435 34.3481H98.7497ZM105.712 45.7106C104.839 50.6809 100.107 54.7198 95.1713 54.7198C92.9105 54.7198 90.9558 53.8599 89.6445 52.3045C88.3115 50.6995 87.7981 48.5326 88.2033 46.2011C89.0785 41.2277 93.8074 37.1888 98.7497 37.1888C101.001 37.1888 102.965 38.0487 104.267 39.6103C105.609 41.2153 106.117 43.3791 105.712 45.7075V45.7106Z"
              fill="#564018"
            />
            <path
              d="M163.615 44.5557C162.845 44.4191 162.112 44.9376 161.972 45.7106C161.103 50.6809 156.365 54.7198 151.435 54.7198C150.653 54.7198 150.019 55.3563 150.019 56.1417C150.019 56.9271 150.653 57.5667 151.435 57.5667C157.683 57.5667 163.661 52.466 164.762 46.2042C164.895 45.4312 164.382 44.6923 163.615 44.5588V44.5557Z"
              fill="#564018"
            />
            <path
              d="M165.155 34.3481C158.908 34.3481 152.926 39.4457 151.828 45.7106C151.698 46.4836 152.209 47.2225 152.976 47.356C153.059 47.3684 153.139 47.3777 153.226 47.3777C153.897 47.3777 154.494 46.8934 154.612 46.2011C155.487 41.2277 160.216 37.1888 165.155 37.1888C165.941 37.1888 166.571 36.5523 166.571 35.7669C166.571 34.9815 165.941 34.3481 165.155 34.3481Z"
              fill="#564018"
            />
            <path
              d="M140.911 46.2042L144.489 25.8294C144.622 25.0564 144.105 24.3175 143.338 24.184C142.571 24.0474 141.835 24.5628 141.699 25.3358L139.386 38.5113C139.216 38.266 139.039 38.0176 138.845 37.7879C136.989 35.5682 134.264 34.3512 131.159 34.3512C124.905 34.3512 118.93 39.4488 117.826 45.7137C117.266 48.8803 117.999 51.873 119.889 54.1331C121.738 56.3435 124.472 57.5698 127.578 57.5698C133.828 57.5698 139.806 52.4691 140.911 46.2073V46.2042ZM127.578 54.7229C125.323 54.7229 123.362 53.863 122.054 52.3076C120.721 50.6964 120.204 48.5357 120.609 46.2042C121.488 41.2308 126.214 37.1919 131.159 37.1919C133.411 37.1919 135.374 38.0518 136.68 39.6134C138.019 41.2184 138.532 43.3822 138.124 45.7106C137.249 50.6809 132.517 54.7198 127.578 54.7198V54.7229Z"
              fill="#564018"
            />
            <path
              d="M145.51 56.39L149.085 36.0152C149.221 35.2391 148.708 34.5065 147.934 34.3699C147.167 34.2302 146.431 34.7486 146.298 35.5216L142.72 55.8995C142.584 56.6725 143.097 57.4021 143.87 57.5449C143.954 57.5573 144.034 57.5666 144.115 57.5666C144.789 57.5666 145.386 57.0792 145.51 56.39Z"
              fill="#564018"
            />
            <path
              d="M60.5568 34.373C59.7836 34.2333 59.0475 34.7517 58.9146 35.5247L55.3362 55.9026C55.197 56.6757 55.7104 57.4052 56.4836 57.548C56.5671 57.5604 56.6475 57.5698 56.731 57.5698C57.4022 57.5698 58.0022 57.0823 58.1228 56.3931L61.6981 36.0183C61.8372 35.2422 61.3238 34.5096 60.5568 34.373Z"
              fill="#564018"
            />
            <path
              d="M114.294 46.2042L117.872 25.8294C118.008 25.0564 117.492 24.3175 116.725 24.184C115.952 24.0474 115.216 24.5628 115.083 25.3358L111.507 45.7106C110.787 49.8054 111.455 53.6985 113.394 56.8868L113.4 56.8806C113.654 57.2779 114.09 57.5418 114.594 57.5418C115.377 57.5418 116.014 56.9023 116.014 56.1137C116.014 55.8064 115.915 55.5301 115.757 55.3035C114.226 52.7205 113.703 49.5571 114.297 46.2042H114.294Z"
              fill="#564018"
            />
          </g>
          <svg className="logo__lines" viewBox="0 0 88 88">
            <g transform="translate(-39 0)">
              <path
                d="M43.8774 88C32.4743 88 21.2907 83.5233 12.8938 75.0946C-0.763948 61.3914 -3.90622 40.6627 5.07214 23.5165C7.11957 19.6049 9.75463 16.0161 12.8938 12.865C14.0227 11.7319 15.8474 11.7319 16.9794 12.865C18.1052 14.0013 18.1052 15.8329 16.9794 16.9661C14.2485 19.7042 11.966 22.818 10.1876 26.2143C2.39379 41.0973 5.12472 59.094 16.9794 70.9936C27.8165 81.8779 44.0011 85.1656 58.2124 79.3881C59.6939 78.7859 61.3733 79.503 61.9764 80.9869C62.5733 82.4709 61.8588 84.1628 60.3805 84.762C55.03 86.9445 49.4258 88.0031 43.8805 88.0031L43.8774 88Z"
                fill="#FFDC00"
              />
              <path
                d="M5.11858 33.6403C4.81239 33.6403 4.49384 33.5906 4.19074 33.482C2.67837 32.9697 1.86496 31.3212 2.38146 29.8062C4.54951 23.383 8.08766 17.6863 12.8939 12.8619C28.4011 -2.70712 53.1867 -4.34009 70.5527 9.04649C71.8146 10.0244 72.0527 11.8436 71.0816 13.1134C70.1074 14.3862 68.2919 14.6222 67.0331 13.6474C51.9558 2.02413 30.4423 3.44909 16.9794 16.9661C12.8042 21.154 9.73611 26.0995 7.84951 31.6689C7.44126 32.8797 6.31549 33.6403 5.11858 33.6403Z"
                fill="#F99800"
              />
              <path
                d="M46.0671 76.3551C38.4279 76.8704 30.8165 74.6445 24.6464 70.0529C10.3237 59.4076 7.30205 39.0421 17.9134 24.6652C18.6619 23.6563 19.4691 22.7156 20.3165 21.8091C20.5825 21.5235 20.8639 21.2472 21.1454 20.9678C27.733 14.38 36.7949 10.9216 46.2805 11.6201C47.8516 11.6947 49.2557 10.5336 49.3733 8.93786C49.4877 7.34215 48.2908 5.95134 46.7042 5.83337C36.2134 5.06656 26.1619 8.64914 18.5289 15.5163C18.433 15.5846 18.3217 15.6156 18.232 15.6994C17.8361 16.0565 17.4773 16.4632 17.1 16.8419C16.7629 17.1803 16.4196 17.5032 16.0949 17.8571C15.1021 18.925 14.1464 20.0364 13.2711 21.2161C0.763896 38.1605 4.32679 62.1613 21.2103 74.719C27.7856 79.6055 35.7495 82.2319 43.8774 82.2319C44.7403 82.2319 45.6031 82.207 46.4629 82.1449C48.0526 82.0394 49.2557 80.6517 49.1475 79.056C49.0361 77.4572 47.6784 76.2464 46.0671 76.3582V76.3551Z"
                fill="#FFD500"
              />
              <path
                d="M46.0671 76.3519C45.1825 76.4109 44.3042 76.414 43.4166 76.3985C34.933 76.2805 26.9722 72.9028 20.9815 66.8522C14.8887 60.7022 11.5547 52.5498 11.5763 43.8789C11.5825 42.277 10.2928 40.9762 8.69383 40.9731H8.68764C7.09485 40.9731 5.80516 42.2646 5.79897 43.8665C5.77114 54.0803 9.70517 63.698 16.8804 70.9439C24.0588 78.1836 33.6155 82.1915 43.7908 82.2225H43.797C43.8248 82.2225 43.8465 82.207 43.8712 82.207V82.2287C44.7372 82.2287 45.6001 82.1977 46.4599 82.1387C48.0496 82.0301 49.2527 80.6486 49.1444 79.0497C49.0331 77.4509 47.6753 76.2402 46.064 76.355L46.0671 76.3519Z"
                fill="#FBBA00"
              />
              <path
                d="M23.0444 67.8084C22.3021 67.8084 21.5629 67.5259 21.0001 66.9577C8.38147 54.2883 8.38147 33.6745 21.0001 21.005C23.431 18.568 26.2021 16.5407 29.2423 14.9947C30.6681 14.2652 32.4063 14.8426 33.1269 16.2707C33.8506 17.6987 33.2846 19.4403 31.8619 20.1699C29.366 21.4427 27.0836 23.1037 25.0856 25.1092C14.7217 35.5185 14.7217 52.4535 25.0856 62.8629C26.2176 63.993 26.2176 65.8308 25.0856 66.9608C24.5258 67.529 23.7836 67.8115 23.0475 67.8115L23.0444 67.8084Z"
                fill="#FFEA6B"
              />
            </g>
          </svg>
        </g>
      </svg>
    </>
  );
};
