"use client";

import { Fragment } from "react";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { cn } from "@/helpers/className";
import { fallbackLng, languages as locales } from "@/i18n/settings";
import { usePageTranslationsPaths } from "@/store/pageTranslations";
import { useTargetAudience } from "@/store/targetAudience.ts";
import { AllowedLanguages, AvailableTargetAudience } from "@/types/generic.ts";

// For an EMPLOYEE, we show 3 locales (even if the page does not have a translation)
const EMPLOYEE_AVAILABLE_LANGUAGES = [
  AllowedLanguages.Nl,
  AllowedLanguages.Fr,
  AllowedLanguages.En,
];

// For an EMPLOYER, we show 2 locales: FR and NL (even if the page does not have a translation)
const EMPLOYER_AVAILABLE_LANGUAGES = [
  AllowedLanguages.Nl,
  AllowedLanguages.Fr,
  // AllowedLanguages.En,
];

interface LanguageSwitcherProps {
  className?: string;
  direction?: "up" | "down";
  locale: AllowedLanguages;
}

export const LanguageSwitcher = ({
  locale,
  direction = "down",
  className,
}: LanguageSwitcherProps) => {
  const { targetAudience } = useTargetAudience();
  type Languages = (typeof locales)[number];
  const selectedLocale = locales.find((l) => l === locale) ?? fallbackLng;

  const langTranslations: Record<Languages, string> = {
    [AllowedLanguages.Fr]: "Français",
    [AllowedLanguages.Nl]: "Nederlands",
    [AllowedLanguages.En]: "English",
  };

  // Get the translated paths from the store (defined in the <TranslatedPaths> component)
  const { pageTranslationsPaths } = usePageTranslationsPaths();

  const localesWithTranslationsPaths = locales
    // Uncomment the next line to hide the current locale from the language switcher
    // ?.filter((l) => l !== selectedLocale)
    .map((locale) => {
      const translatedPath = pageTranslationsPaths.find(
        (translation) => translation?.language === locale,
      );

      return {
        locale: locale,
        title: langTranslations[locale],
        path: !translatedPath ? `/${locale}` : translatedPath.url,
      };
    })
    .filter((localeWithTranslationsPaths) => {
      if (targetAudience === AvailableTargetAudience.EMPLOYEE) {
        return EMPLOYEE_AVAILABLE_LANGUAGES.includes(
          localeWithTranslationsPaths.locale,
        );
      }
      if (targetAudience === AvailableTargetAudience.EMPLOYER) {
        return EMPLOYER_AVAILABLE_LANGUAGES.includes(
          localeWithTranslationsPaths.locale,
        );
      }
      return true;
    });

  return (
    <Popover className="relative">
      <Popover.Button
        className={cn(
          "inline-flex items-center gap-x-1 text-sm text-black-400 outline-none hover:text-green",
          className,
        )}
      >
        <span>{langTranslations[selectedLocale]}</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={cn(
            "absolute -left-4 z-10 mt-2 flex w-screen max-w-max",
            direction === "up" ? "bottom-full" : "top-full",
          )}
        >
          <div className="max-w-screen flex w-32 flex-col overflow-hidden rounded-lg bg-white py-1 text-sm shadow-lg ring-1 ring-gray-900/5">
            {localesWithTranslationsPaths.map(
              ({ locale, path, title }, index) => (
                <Link
                  key={index}
                  href={path}
                  className={cn(
                    "px-4 py-2 text-sm text-gray-900 transition-colors hover:bg-gray-100",
                    locale === selectedLocale && "font-bold",
                  )}
                >
                  {title}
                </Link>
              ),
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
