import Link from "next/link";
import {AllowedLanguages} from "@/types/generic";

interface FooterLoginSectionProps {
  className?: string;
  i18n: {
    login_at: string;
  };
  locale: AllowedLanguages;
}

export const FooterLoginSection = ({
  className,
  i18n,
  locale,
}: FooterLoginSectionProps) => {
  return (
    <div className={className}>
      <div className="whitespace-nowrap leading-none lg:mb-6">
        {i18n.login_at}
      </div>

      <div className="flex flex-col flex-wrap gap-6 sm:flex-row">
        <Link href={`/${locale}/myvivaldis`} className="link">
          MyVivaldis
        </Link>
        <Link href={"https://vivaconnect.vivaldisinterim.be/"} className="link">
          Vivaconnect
        </Link>
      </div>
    </div>
  );
};
