import type { MenuFieldsFragment } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import LinkWithSuffix from "@/components/molecules/LinkWithSuffix";

interface FooterNavSectionProps {
  children?: React.ReactNode;
  className?: string;
  columns?: 1 | 2;
  navigation?: MenuFieldsFragment;
  title?: string;
}

export const FooterNavSection = ({
  title,
  navigation,
  className,
  columns,
  children,
}: FooterNavSectionProps) => {
  const displayTitle = title ?? navigation?.name;

  return (
    <div className={cn(className)}>
      {displayTitle && <h3 className="footer-nav-title">{displayTitle}</h3>}

      <ul className={cn("space-y-4", columns === 2 ? "lg:columns-2" : "")}>
        {navigation?.items?.map((item, index) => (
          <li
            key={index}
            className="flex max-w-[268px] items-center justify-between gap-2"
          >
            <LinkWithSuffix link={item}/>
          </li>
        ))}

        {children}
      </ul>
    </div>
  );
};
